<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useProductivityDashboardStore } from '@/stores/productivity-dashboard';
import { area_chart_options } from '@/plugins/apexcharts/area_chart_options';
import { line_chart_options } from '@/plugins/apexcharts/line_chart_options';
import { computed } from 'vue';
import NoDataAvailable from '@/components/common/NoDataAvailable.vue';

const productivityDashboardStore = useProductivityDashboardStore()
const { loading, agentCountProductionCIPSeries, agentCountAddsRemovalsNetSeries, agentCountTrendLabels } = storeToRefs(productivityDashboardStore)

const agentAreaChartOptions = computed(() => {
    return {
        ...area_chart_options,
        labels: agentCountTrendLabels.value
    }
})

const agentLineChartOptions = computed(() => {
    return {
        ...line_chart_options,
        dataLabels: {
            enabled: true,
        },
        labels: agentCountTrendLabels.value
    }
})

</script>

<template>
    <section class="tw-flex tw-flex-col tw-gap-y-6">
        <h3 class="tw-mr-auto tw-w-full tw-text-left tw-text-lg tw-font-black tw-text-shyftoff-purple">
            Agent Count Trend
        </h3>

        <template v-if="loading">
            <v-skeleton-loader class="tw-h-[500px] tw-w-full tw-rounded-lg" />
        </template>

        <template v-else>
            <!-- Production/CIP Chart -->
            <template v-if="agentCountProductionCIPSeries && agentCountProductionCIPSeries.length > 0">
                <apexchart width="100%" height="500" :options="agentAreaChartOptions"
                    :series="agentCountProductionCIPSeries" />
            </template>
            <template v-else>
                <NoDataAvailable message="No Production/CIP data available." />
            </template>

            <!-- Adds/Removals/Net Chart -->
            <template v-if="agentCountAddsRemovalsNetSeries && agentCountAddsRemovalsNetSeries.length > 0">
                <apexchart width="100%" height="500" :options="agentLineChartOptions"
                    :series="agentCountAddsRemovalsNetSeries" />
            </template>
            <template v-else>
                <NoDataAvailable message="No Adds/Removals/Net data available." />
            </template>
        </template>
    </section>
</template>