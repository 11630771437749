<script setup lang="ts">
import { ref, inject, computed, watch, nextTick, toRefs } from 'vue'
import { RouterView } from 'vue-router'
import NavigationMain from '@/components/NavigationMain.vue'
import useUser from '@/composables/useUser'
import { onMounted } from 'vue'
import { useNotificationStore } from '@/stores/notifications'
import { useProfileStore } from '@/stores/profile'
import useCampaigns from '@/composables/useCampaigns'
import { useLayoutStore } from '@/stores/layout'

const { appIsLoading } = toRefs(useLayoutStore())


let menuIsOpen = ref(false)
const emitter: any = inject('emitter')
const notificationStore = useNotificationStore()
const { getUserCampaigns } = useCampaigns()

const profileStore = useProfileStore()
const errorNotification = computed({
  get() {
    return profileStore.error
  },
  set(val) {
    if (!val)
    profileStore.error = val
  }
})
const showErrorNotif = ref(false)
watch(() => profileStore.error, (newVal) => {
  if (newVal) showErrorNotif.value = true
})

const { getUserNotifications } = useUser()

watch(() => profileStore.isAgentRole, async () => {
  if (notificationStore.getNotifications == null) {
    await Promise.all([getUserNotifications({ type: 'all', options: { limit: 5 } }), getUserNotifications({ type: 'unread', options: { is_read: false } })])
  }
})

onMounted(async () => {
  if (notificationStore.getNotifications == null) {
    await Promise.all(
      [
        getUserNotifications({ type: 'all', options: { limit: 5 } }),
        getUserNotifications({ type: 'unread', options: { is_read: false } })
      ]
    )
  }
  await nextTick()
  const socket: WebSocket = new WebSocket('wss://dev-api.agent-trainer.shyftoff.com/ws/notifications')
  const localStorageAuthObj: { username: string, token: string } = JSON.parse( localStorage.getItem('auth') || '{}' )
  socket.addEventListener('open', () => {
    socket.send(JSON.stringify({ 'type': 1, 'payload': { 'token': localStorageAuthObj.token } }))
  })
  socket.addEventListener('message', (event) => {
    const { payload } = JSON.parse(event.data)
    if ('notification' in payload) {
      notificationStore.addNotification(payload.notification)
    }
  })
})

emitter.on('closeMenu', () => {
  menuIsOpen.value = false
})

const openActiveCampaigns = ref(false)
emitter.on('toggleActiveCampaigns', (val: boolean) => {
  openActiveCampaigns.value = val
})

function openMenu() {
  menuIsOpen.value = true
  emitter.emit('openMenu')
}
</script>

<template>
  <!-- <div class="head-bg"></div> -->
  <v-progress-linear v-if="appIsLoading" indeterminate class="tw-absolute tw-z-30" color="secondary"></v-progress-linear>
  <NavigationMain />
  <div class="tw-fixed md:tw-hidden tw-top-6 tw-left-3 tw-w-[45px] tw-h-[45px] tw-z-50" :class="{'tw-hidden': menuIsOpen}">
    <v-btn :active="menuIsOpen" @click="openMenu" class="openMenu">
      <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.6667 7C18.6667 7.20628 18.5847 7.40411 18.4389 7.54997C18.293 7.69583 18.0952 7.77778 17.8889 7.77778H0.777778C0.571498 7.77778 0.373668 7.69583 0.227806 7.54997C0.0819444 7.40411 0 7.20628 0 7C0 6.79372 0.0819444 6.59589 0.227806 6.45003C0.373668 6.30417 0.571498 6.22222 0.777778 6.22222H17.8889C18.0952 6.22222 18.293 6.30417 18.4389 6.45003C18.5847 6.59589 18.6667 6.79372 18.6667 7ZM0.777778 1.55556H17.8889C18.0952 1.55556 18.293 1.47361 18.4389 1.32775C18.5847 1.18189 18.6667 0.984057 18.6667 0.777778C18.6667 0.571498 18.5847 0.373668 18.4389 0.227806C18.293 0.0819444 18.0952 0 17.8889 0H0.777778C0.571498 0 0.373668 0.0819444 0.227806 0.227806C0.0819444 0.373668 0 0.571498 0 0.777778C0 0.984057 0.0819444 1.18189 0.227806 1.32775C0.373668 1.47361 0.571498 1.55556 0.777778 1.55556ZM17.8889 12.4444H0.777778C0.571498 12.4444 0.373668 12.5264 0.227806 12.6722C0.0819444 12.8181 0 13.0159 0 13.2222C0 13.4285 0.0819444 13.6263 0.227806 13.7722C0.373668 13.9181 0.571498 14 0.777778 14H17.8889C18.0952 14 18.293 13.9181 18.4389 13.7722C18.5847 13.6263 18.6667 13.4285 18.6667 13.2222C18.6667 13.0159 18.5847 12.8181 18.4389 12.6722C18.293 12.5264 18.0952 12.4444 17.8889 12.4444Z" fill="#F5F5F5"/>
      </svg>
    </v-btn>
  </div>
  <main class="main-wrapper md:tw-pl-[70px] xl:tw-pl-[250px] tw-transition-all" :class="{'md:tw-pl-[320px] xl:tw-pl-[500px]': openActiveCampaigns}">
    <RouterView />
  </main>
  <VSnackbar v-model="showErrorNotif" :timeout="3000" :color="'red-darken-2'" class="tw-ml-[70px] xl:tw-ml-[250px]" :class="{'md:tw-pl-[320px] xl:tw-pl-[500px]': openActiveCampaigns}">
    {{ errorNotification }}
  </VSnackbar>
</template>

<style lang="scss">
#app,
main {
  @apply tw-flex tw-shrink tw-grow tw-basis-full #{!important};
}
.v-input {
  @apply tw-bg-transparent;
  &:hover {
    .v-field__outline {
      .v-field-label {
        @apply tw-text-shyftoff-purple;
      }
      > div,
      .v-field__outline__notch:before,
      .v-field__outline__notch:after {
        @apply tw-border-[#d1b9fc] #{!important};
      }
    }
  }
  .v-field--focused {
    .v-field__outline {
      .v-field-label {
        @apply tw-text-shyftoff-purple;
      }
      .v-field__outline__notch {
        @apply tw-border-t-2 tw-border-b-2 tw-border-[#d1b9fc];
      }
    }
  }
  .v-field__outline,
  .v-field--variant-outlined {
    > div,
    .v-field__outline__notch:before,
    .v-field__outline__notch:after {
      @apply tw-border-grey-border/50 #{!important};
    }
    .v-field__outline__notch:after,
    .v-field__outline__start,
    .v-field__outline__end {
      @apply tw-opacity-100 tw-bg-transparent;
    }
  }
  .v-input__control {
    .v-field {
      @apply tw-rounded-lg;
      &:not(.v-field--active) {
        .v-field__outline {
          .v-field__outline__notch:before {
            @apply tw-opacity-100;
          }

        }
        .v-field-label {
          @apply tw-text-gray-500 tw-opacity-100;
        }
      }
      &.v-field--disabled {
        @apply tw-opacity-90 tw-text-gray-500;
      }
    }
  }
  .v-field-label {
    @apply tw-text-slate-500 tw-opacity-100;
  }
  .v-input__details {
    @apply tw-hidden;
  }
}

.v-input--error {
  &:hover {
    .v-field__outline {
      > div,
      .v-field__outline__notch:before,
      .v-field__outline__notch:after {
        @apply tw-border-red-300 #{!important};
      }
    }
  }
  .v-input__details {
    @apply tw-flex;
    .v-messages {
      .v-messages__message {
        @apply tw-leading-4 #{!important};
      }
    }
  }
  .v-field-label {
    @apply tw-text-red-700 #{!important};
  }
  .v-field__outline {
    > div,
    .v-field__outline__notch:before,
    .v-field__outline__notch:after {
      @apply tw-border-red-500 #{!important};
    }
  }
}
.v-btn {
  @apply tw-text-regular-text;
  &:disabled {
    @apply tw-bg-slate-300 #{!important};
    .v-btn__content {
      @apply tw-text-slate-900;
    }
  }
}
.v-select,
.v-field  {
  @apply tw-text-regular-text tw-min-w-[250px];
  .v-icon {
    @apply tw-text-shyftoff-purple/80 tw-opacity-100;
  }
}
.v-checkbox {
  .v-label {
    @apply tw-opacity-100;
  }
}
.v-overlay-container {
  .v-overlay__content {
    .v-card {
      @apply tw-shadow-[0_0_8px_0_rgba(0,_0,_0,_0.08),_0_0_15px_0_rgba(0,_0,_0,_0.02),_0_0_20px_4px_rgba(0,_0,_0,_0.06)] #{!important};
    }
  }
}
.mdi-checkbox-blank-outline,
.mdi-checkbox-marked {
  @apply tw-text-shyftoff-purple;
}
</style>

<style scoped lang="scss">
.main-wrapper {
  background-color: white;
  min-height: 100vh;
  max-width: 100%;
}
.head-bg {
  background-color: white;
  width: 100%;
  height: rem(300px);
  position: absolute;
  z-index: -1;
}
::v-deep(.v-btn.openMenu) {
  @apply tw-block tw-bg-shyftoff-purple tw-rounded-[100%] tw-p-0 tw-leading-none tw-opacity-80;
  @apply tw-w-[45px] tw-h-[45px] tw-min-w-min #{!important};
}

</style>
