import type { TableHeader } from '@/types/dashboard'

export const PRODUCTION_TABLE_HEADERS: TableHeader[] = [
  { title: 'Name', key: 'name', },
  { title: 'ShyftOff ID', key: 'shyftoff_id', align: 'center', sortable: false },
  { title: 'Personal Email', key: 'email', sortable: false },
  { title: 'ShyftOff Email', key: 'shyftoff_email', sortable: false },
  { title: 'Agent Status', key: 'status', }
]

export const CIP_TABLE_HEADERS: TableHeader[] = [
  { title: 'Name', key: 'name' },
  { title: 'ShyftOff ID', key: 'shyftoff_id', align: 'center', sortable: false },
  { title: 'Personal Email', key: 'email', sortable: false },
  { title: 'Certification Name', key: 'qualification', },
  { title: 'Certification Progress', key: 'progress' },
  { title: 'Number of Quizzes', key: 'quizzes' },
  { title: 'Average Score', key: 'score' },
  { title: 'Agent Status', key: 'status', sortable: false }
]

export const INTRA_DAY_PERFORMANCE_TABLE_HEADERS: TableHeader[] = [
  { title: 'SO Agent', key: 'shyftoff_id', sortable: false },
  { title: 'Agent Name', key: 'name' },
  { title: 'Campaign Name', key: 'campaign_name' },
  { title: 'Actual', key: 'actual_hrs' },
  { title: 'Scheduled', key: 'scheduled_hrs' },
  { title: 'Reliable', key: 'reliable_hrs' },
  { title: 'Reliability %', key: 'reliability_percentage' },
  { title: 'Ureliable Hrs', key: 'unreliable_hrs' },
  { title: 'Missed Hrs', key: 'missed_hrs' },
]

export const INTRA_DAY_PRODUCTIVITY_TABLE_HEADERS: TableHeader[] = [
  { title: 'Shift Start (Eastern)', key: 'report_date', },
  { title: 'Released Hrs', key: 'released_hrs', },
  { title: 'Scheduled Hrs', key: 'scheduled_hrs', },
  { title: 'Actual Hrs', key: 'actual_hrs', },
  { title: 'Locked Hrs', key: 'locked_hrs', },
  { title: '% To Lock', key: 'locked_percentage', },
]

export const NEED_REVIEW_TABLE_HEADERS: TableHeader[] = [
  { title: 'Agent', key: 'agent_name' },
  { title: 'Qualification Status', key: 'qualification_status' },
  { title: 'Verification Status', key: 'verification_status' },
  { title: 'Transaction Id', key: 'transaction_id', align: 'center', sortable: false },
  { title: 'Match Response', key: 'match_response', align: 'center', sortable: false },
  { title: 'Created At', key: 'created_at' },
  { title: 'Actions', key: 'actions', align: 'center', sortable: false },
]

export const ITEMS_PER_PAGE_OPTIONS = [
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' }
]