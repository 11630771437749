<script setup lang="ts">
import BaseDataTable from '@/components/common/DataTable.vue'
import { INTRA_DAY_PERFORMANCE_TABLE_HEADERS } from '@/constants/tables'
import { useProductivityDashboardStore } from '@/stores/productivity-dashboard';
import type { TableOptions } from '@/types/dashboard';
import { storeToRefs } from 'pinia';
import NoDataAvailable from '@/components/common/NoDataAvailable.vue';

const productivityDashboardStore = useProductivityDashboardStore()
const {
    loading,
    agentsProductivity,
} = storeToRefs(productivityDashboardStore)
</script>

<template>
    <section class="tw-flex tw-flex-col tw-gap-y-4">
        <div class="tw-max-w-full">
            <div v-if="loading">
                <VSkeletonLoader type="table-thead, table-row-divider@7" />
            </div>

            <BaseDataTable v-else-if="agentsProductivity.agents.length && !loading" :items="agentsProductivity.agents"
                :headers="INTRA_DAY_PERFORMANCE_TABLE_HEADERS" :loading="agentsProductivity.loading"
                :page="agentsProductivity.page" :items-per-page="agentsProductivity.itemsPerPage"
                :items-length="agentsProductivity.total" class="tw-max-h-[450px] md:tw-max-h-[800px]"
                @update:options="(options: TableOptions) => productivityDashboardStore.handleTableUpdate(options)">
                <template v-slot:loader>
                    <v-progress-linear color="primary" :height="3" indeterminate />
                </template>

                <template v-slot:headers="{ headers, sortBy, toggleSort, isSorted }">
                    <tr>
                        <template v-for="(headerRow, rowIndex) in headers" :key="rowIndex">
                            <th v-for="header in headerRow" :key="header.title"
                                class="tw-group !tw-border-none tw-text-[13px] !tw-font-bold tw-uppercase tw-text-light-text"
                                :class="{ 'tw-cursor-pointer': header.sortable }"
                                @click="header.sortable ? toggleSort(header) : null">
                                <div class="tw-flex tw-w-full tw-items-center tw-gap-x-2"
                                    :class="{ 'tw-justify-center': header.key === 'shyftoff_id', 'tw-justify-between': header.sortable }">
                                    {{ header.title }}

                                    <span v-if="header.sortable" class="tw-flex tw-items-center">
                                        <i v-if="!isSorted(header)"
                                            class="mdi mdi-arrow-up tw-text-base tw-text-transparent group-hover:tw-text-gray-400" />

                                        <i v-else-if="sortBy.find((sort: any) => sort.key === header.key)?.order === 'asc'"
                                            class="mdi mdi-arrow-up tw-text-base" />
                                        <i v-else class="mdi mdi-arrow-down tw-text-base" />
                                    </span>
                                </div>
                            </th>
                        </template>
                    </tr>
                </template>

                <template v-slot:[`item.shyftoff_id`]="{ item }">
                    <span
                        class="tw-rounded-full tw-border tw-border-solid tw-border-light-purple tw-px-4 tw-py-1 tw-text-sm tw-font-medium tw-text-regular-text">
                        {{ item.shyftoff_id }}
                    </span>
                </template>

                <template v-slot:[`item.actual_hrs`]="{ item }">
                    {{ item.actual_hrs?.toFixed(2) }}
                </template>

                <template v-slot:[`item.scheduled_hrs`]="{ item }">
                    {{ item.scheduled_hrs?.toFixed(2) }}
                </template>

                <template v-slot:[`item.reliable_hrs`]="{ item }">
                    {{ item.reliable_hrs?.toFixed(2) }}
                </template>

                <template v-slot:[`item.reliability_percentage`]="{ item }">
                    <div class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center"
                        :class="{ 'tw-bg-green-800/30': item.reliability_percentage <= 0.3, 'tw-bg-green-800/50': item.reliability_percentage > 0.3 && item.reliability_percentage <= 0.79, 'tw-bg-green-800/70 tw-text-white': item.reliability_percentage >= 0.8 }">
                        {{ item.reliability_percentage.toFixed(2) }}
                    </div>
                </template>

                <template v-slot:[`item.unreliable_hrs`]="{ item }">
                    <div class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center"
                        :class="{ 'tw-bg-[#FED766]/30': item.unreliable_hrs <= 0, 'tw-bg-[#FED766]/70': item.unreliable_hrs <= 4, 'tw-bg-[#FED766]': item.unreliable_hrs > 4 }">
                        {{ item.unreliable_hrs?.toFixed(2) }}
                    </div>
                </template>

                <template v-slot:[`item.missed_hrs`]="{ item }">
                    <div class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center"
                        :class="{ 'tw-bg-red-700/30': item.missed_hrs <= 0, 'tw-bg-red-700/50': item.missed_hrs <= 4, 'tw-bg-red-700/70 tw-text-white': item.missed_hrs > 4 }">
                        {{ item.missed_hrs?.toFixed(2) }}
                    </div>
                </template>
            </BaseDataTable>

            <template v-else>
                <NoDataAvailable />
            </template>
        </div>
    </section>
</template>