<script setup lang="ts">
import { onMounted, ref } from 'vue'
import GeographyMap from '@/components/agent-dashboard/GeographyMap.vue';
import ProductionTable from '@/components/agent-dashboard/ProductionTable.vue';
import AgentDashboardHeader from '@/components/agent-dashboard/AgentDashboardHeader.vue';
import { useCampaignStore } from '@/stores/campaigns';
import { storeToRefs } from 'pinia';
import useCampaigns from '@/composables/useCampaigns';
import { useAgentsDashboardStore } from '@/stores/agents-dashboard';
import CertificationTable from '@/components/agent-dashboard/CertificationTable.vue';
import type { Campaign } from '@/types/campaign';
import { useProfileStore } from '@/stores/profile';

const campaignsList = ref<Campaign[]>([])

const profileStore = useProfileStore()
const agentsDashboardStore = useAgentsDashboardStore()
const {
    selectedCampaign,
    loading,
    summary,
    geographies,
} = storeToRefs(agentsDashboardStore)

const { getUserCampaigns, fetchAllCampaigns } = useCampaigns()
const { campaigns, activeCampaigns } = storeToRefs(useCampaignStore())

onMounted(async () => {
    loading.value = true

    if (profileStore.isAdmin) {
        await fetchAllCampaigns({ is_active: true })
        campaignsList.value = campaigns.value
        selectedCampaign.value = campaigns.value[0]
    } else {
        await getUserCampaigns({ is_active: true })
        campaignsList.value = activeCampaigns.value
        selectedCampaign.value = activeCampaigns.value[0]
    }

    loading.value = false
})
</script>

<template>
    <article class="tw-relative tw-flex tw-flex-col tw-gap-y-8">
        <AgentDashboardHeader v-model:selected-campaign="selectedCampaign" :active-campaigns="campaignsList"
            :loading="loading" :summary="summary" />

        <ProductionTable />

        <CertificationTable />

        <GeographyMap :geographies="geographies" :loading="loading" />
    </article>
</template>