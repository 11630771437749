<script setup lang="ts">
const props = defineProps(['tableHeaders', 'tableContent'])
</script>
<template>
  <v-table v-if="props.tableHeaders && props.tableContent" density="comfortable" class="tw-mt-2 tw-bg-transparent">
    <thead>
      <tr>
        <th v-for="(headerContent, index) in props.tableHeaders" :key="index" class="!tw-text-sm !tw-font-extrabold tw-uppercase tw-border-b tw-border-solid !tw-border-[rgba(87,35,180,0.1)] !tw-text-[#8C70BE]">
          {{ headerContent }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td v-for="(tdContent, index) in props.tableContent" :key="index" class="!tw-text-[#403870]">
          {{ tdContent }}
        </td>
      </tr>
    </tbody>
   </v-table>
</template>
