<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useProductivityDashboardStore } from '@/stores/productivity-dashboard';
import { ref } from 'vue';
import NoDataAvailable from '../common/NoDataAvailable.vue';
import { productivity_chart_options } from '@/plugins/apexcharts/mixed_bar_line_options';

const dateGroupButtons = [
    {
        value: 'monthly'
    },
    {
        value: 'weekly'
    },
    {
        value: 'daily',
    }
]

const productivityDashboardStore = useProductivityDashboardStore()
const { loading, selectedDateGroup, productivityOverviewChartSeries } = storeToRefs(productivityDashboardStore)

const chart = ref()

const handleDateGroupChange = (dateGroup: string) => {
    selectedDateGroup.value = dateGroup;
}
</script>

<template>
    <section class="tw-flex tw-flex-col tw-gap-y-6">
        <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-between tw-gap-y-4">
            <h3 class="tw-mr-auto tw-flex tw-text-left tw-text-lg tw-font-black tw-text-shyftoff-purple">
                Productivity Overview</h3>

            <div class="tw-flex tw-gap-x-4">
                <button v-for="(button, index) in dateGroupButtons" :key="index"
                    class="tw-w-[151px] tw-rounded-lg tw-px-4 tw-py-2 tw-font-sans tw-capitalize tw-text-white" :class="{
                        'tw-bg-primary': selectedDateGroup === button.value,
                        'tw-bg-gray-400': selectedDateGroup !== button.value
                    }" @click="handleDateGroupChange(button.value)"
                    :disabled="loading || !productivityOverviewChartSeries.length">
                    {{ button.value }}
                </button>
            </div>
        </div>

        <template v-if="loading">
            <v-skeleton-loader class="tw-h-[500px] tw-w-full tw-rounded-lg" />
        </template>

        <apexchart v-else-if="productivityOverviewChartSeries.length && !loading" ref="chart" width="100%" height="500"
            :options="productivity_chart_options" :series="productivityOverviewChartSeries" />

        <template v-else>
            <NoDataAvailable />
        </template>
    </section>
</template>