export const line_chart_options = {
  chart: {
    type: 'line',
    zoom: {
      enabled: false
    },
    toolbar: {
      tools: {
        download: true,
        selection: false,
        zoom: false,
        pan: false,
        reset: false
      }
    }
  },
  xaxis: {
    type: 'datetime',
    labels: {
      show: true,
      style: {
        fontSize: '13px',
        fontFamily: 'Nunito',
      }
    }
  },
  markers: {
    size: 0,
    style: 'hollow',
  },
  stroke: {
    width: 4,
    dashArray: 4
  },
  legend: {
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Nunito',
    markers: {
      size: 8,
      shape: 'circle'
    }
  }
}

export const trend_chart_options = {
  ...line_chart_options,
  dataLabels: {
    enabled: true,
    enabledOnSeries: [0]
  },
  yaxis: {
    labels: {
      enabled: false,
      style: {
        fontSize: '13px',
        fontFamily: 'Nunito',
      }
    }
  },
}
