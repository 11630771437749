<script setup lang="ts">
import type { CampaignApplication } from '@/types/campaign-applications'
import { computed } from 'vue';

interface Props {
    application: CampaignApplication | null
}

const props = defineProps<Props>()

const hasAnyScore = computed(() => {
    if (!props.application) return false;

    return !!(
        props.application.tech_support_score ||
        props.application.isp_support_score ||
        props.application.customer_centric_score ||
        props.application.sales_score
    );
});
</script>

<template>
    <v-menu :close-on-content-click="false" location="right">
        <template v-slot:activator="{ props }">
            <VBtn v-bind="props" :rounded="true" width="45" height="45" flat :disabled="!application"
                class="group disabled:bg-white tw-inline-block tw-min-w-0 tw-px-0 tw-text-[#AAABB3] hover:tw-text-[#5723B4] disabled:!tw-bg-white">
                <i class="mdi mdi-eye tw-mx-0 tw-text-2xl" :class="{ 'mdi-eye-remove-outline': !application }" />
            </VBtn>
        </template>

        <v-card v-if="application" max-height="500"
            class="tw-scrollbar-thin tw-scrollbar-track-[#F3EFFA] tw-scrollbar-thumb-[#FF90D4]">
            <!-- Policy Agreement -->
            <div class="tw-mb-4 last-of-type:!tw-mb-0">
                <h4>Has complied with policy agreement: </h4>
                <ul class="mt-2">
                    <li class="tw-uppercase">{{ application.has_complied_with_policy_agreement }}</li>
                </ul>
            </div>

            <!-- Resume Link -->
            <div v-if="application.resume_url" class="tw-mb-4">
                <h4>Resume Link:</h4>
                <ul class="pl-4 mt-2 tw-list-disc">
                    <li>
                        <a :href="application.resume_url" target="_blank"
                            class="tw-text-sm tw-font-normal tw-text-shyftoff-purple hover:tw-underline">
                            <svg class="tw-mr-1 tw-inline-block tw-align-middle" width="16" height="16"
                                viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M2.16667 0.5C1.25578 0.5 0.5 1.25578 0.5 2.16667V13.8333C0.5 14.7442 1.25578 15.5 2.16667 15.5H13.8333C14.7442 15.5 15.5 14.7442 15.5 13.8333V8H13.8333V13.8333H2.16667V2.16667H8V0.5H2.16667ZM9.66667 0.5V2.16667H12.6549L4.91081 9.91081L6.08919 11.0892L13.8333 3.34505V6.33333H15.5V0.5H9.66667Z"
                                    fill="#5723B4" />
                            </svg>
                            Resume Url
                        </a>
                    </li>
                </ul>
            </div>

            <!-- Skills -->
            <div v-if="application.skills?.length" class="tw-mb-4">
                <h4>Skills:</h4>
                <ul class="pl-4 mt-2 tw-list-disc">
                    <li v-for="skill in application.skills" :key="skill">
                        {{ skill }}
                    </li>
                </ul>
            </div>

            <!-- Job Titles -->
            <div v-if="application.job_titles?.length" class="tw-mb-4">
                <h4>Job titles:</h4>
                <ul class="pl-4 mt-2 tw-list-disc">
                    <li v-for="jobTitle in application.job_titles" :key="jobTitle">
                        {{ jobTitle }}
                    </li>
                </ul>
            </div>

            <!-- Employers -->
            <div v-if="application.employers?.length" class="tw-mb-4">
                <h4>Employers:</h4>
                <ul class="pl-4 mt-2 tw-list-disc">
                    <li v-for="employer in application.employers" :key="employer">
                        {{ employer }}
                    </li>
                </ul>
            </div>

            <!-- Education -->
            <div v-if="application.education" class="tw-mb-4">
                <h4>Education:</h4>
                <ul class="pl-4 mt-2 tw-list-disc">
                    <li>{{ application.education }}</li>
                </ul>
            </div>

            <!-- Scores Section -->
            <template v-if="hasAnyScore">
                <!-- Tech Support Score -->
                <div v-if="application.tech_support_score" class="tw-mb-4">
                    <h4>Tech support score:</h4>
                    <ul class="mt-2">
                        <li>{{ application.tech_support_score }}</li>
                    </ul>
                </div>

                <!-- ISP Support Score -->
                <div v-if="application.isp_support_score" class="tw-mb-4">
                    <h4>ISP support score:</h4>
                    <ul class="mt-2">
                        <li>{{ application.isp_support_score }}</li>
                    </ul>
                </div>

                <!-- Customer Centric Score -->
                <div v-if="application.customer_centric_score" class="tw-mb-4">
                    <h4>Customer centric score:</h4>
                    <ul class="mt-2">
                        <li>{{ application.customer_centric_score }}</li>
                    </ul>
                </div>

                <!-- Sales Score -->
                <div v-if="application.sales_score" class="tw-mb-4">
                    <h4>Sales score:</h4>
                    <ul class="mt-2">
                        <li>{{ application.sales_score }}</li>
                    </ul>
                </div>
            </template>
        </v-card>
    </v-menu>
</template>

<style scoped lang="scss">
:deep(.v-btn__overlay) {
    background-color: white;
}
</style>