import { z } from "zod";

export const campaignApplicationQuestionsSchema = z.object({
    policyAgreement: z.boolean({
        required_error: 'You must agree to the policies',
    }).nullable()
        .refine(val => val === true, {
            message: 'You must agree to the policies'
        }),
    pdfResume: z.union([
        z.instanceof(File, { message: 'Resume is required' }),
        z.instanceof(File, { message: 'Resume is required' }).array().min(1, 'Resume is required')
      ])
});