<script setup lang="ts">
import DashboardHeader from '@/components/common/DashboardHeader.vue';
import PerformanceMetricsCharts from '@/components/performance-dashboard/PerformanceMetricsCharts.vue';
import PriorityBoarding from '@/components/performance-dashboard/PriorityBoarding.vue';
import useCampaigns from '@/composables/useCampaigns';
import { useCampaignStore } from '@/stores/campaigns';
import { usePerformanceDashboardStore } from '@/stores/performance-dashboard';
import type { Campaign } from '@/types/campaign';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useProfileStore } from '@/stores/profile';

const campaignsList = ref<Campaign[]>([])

const profileStore = useProfileStore()
const performanceDashboardStore = usePerformanceDashboardStore()
const {
    selectedCampaign,
    loading,
} = storeToRefs(performanceDashboardStore)

const { getUserCampaigns, fetchAllCampaigns } = useCampaigns()
const { campaigns, activeCampaigns } = storeToRefs(useCampaignStore())

const handleUpdateSelectedCampaign = (campaign: Campaign) => {
    selectedCampaign.value = campaign;
}

onMounted(async () => {
    loading.value = true

    if (profileStore.isAdmin) {
        await fetchAllCampaigns({ is_active: true })
        campaignsList.value = campaigns.value
        selectedCampaign.value = campaigns.value[0]
    } else {
        await getUserCampaigns({ is_active: true })
        campaignsList.value = activeCampaigns.value
        selectedCampaign.value = activeCampaigns.value[0]
    }

    loading.value = false
})
</script>

<template>
    <article class="tw-relative tw-flex tw-flex-col tw-gap-y-8">
        <DashboardHeader title="Performance Dashboard" :selected-campaign="selectedCampaign"
            :active-campaigns="campaignsList" :loading="loading"
            @update:selected-campaign="handleUpdateSelectedCampaign" />

        <PerformanceMetricsCharts />

        <PriorityBoarding />
    </article>
</template>

<style scoped></style>
