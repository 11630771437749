<script setup lang="ts">
import ActiveAgentsRateChart from '@/components/productivity-dashboard/ActiveAgentsRateChart.vue';
import AgentCountTrendCharts from '@/components/productivity-dashboard/AgentCountTrendCharts.vue';
import IntraDayDashboard from '@/components/productivity-dashboard/intra-day/IntraDayDashboard.vue';
import ProductivityDashboardHeader from '@/components/productivity-dashboard/ProductivityDashboardHeader.vue';
import ProductivityOverviewChart from '@/components/productivity-dashboard/ProductivityOverviewChart.vue';
import useCampaigns from '@/composables/useCampaigns';
import { useCampaignStore } from '@/stores/campaigns';
import { useProductivityDashboardStore } from '@/stores/productivity-dashboard';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import type { Campaign } from '@/types/campaign';
import { useProfileStore } from '@/stores/profile';

const campaignsList = ref<Campaign[]>([])

const profileStore = useProfileStore()
const productivityDashboardStore = useProductivityDashboardStore()
const {
    selectedCampaign,
    loading,
    summary,
} = storeToRefs(productivityDashboardStore)

const { getUserCampaigns, fetchAllCampaigns } = useCampaigns()
const { campaigns, activeCampaigns } = storeToRefs(useCampaignStore())

onMounted(async () => {
    loading.value = true

    if (profileStore.isAdmin) {
        await fetchAllCampaigns({ is_active: true })
        campaignsList.value = campaigns.value
        selectedCampaign.value = campaigns.value[0]
    } else {
        await getUserCampaigns({ is_active: true })
        campaignsList.value = activeCampaigns.value
        selectedCampaign.value = activeCampaigns.value[0]
    }

    loading.value = false
})
</script>

<template>
    <article class="tw-relative tw-flex tw-flex-col tw-gap-y-8">
        <ProductivityDashboardHeader v-model:selected-campaign="selectedCampaign" :active-campaigns="campaignsList"
            :loading="loading" :summary="summary" />

        <ProductivityOverviewChart />

        <AgentCountTrendCharts />

        <ActiveAgentsRateChart />

        <IntraDayDashboard />
    </article>
</template>

<style scoped></style>