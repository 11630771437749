export const area_chart_options = {
  chart: {
    type: 'area',
    zoom: {
      enabled: false
    },
    toolbar: {
      tools: {
        download: true,
        selection: false,
        zoom: false,
        pan: false,
        reset: false
      }
    }
  },
  xaxis: {
    type: 'datetime',
    labels: {
      show: true,
      style: {
        fontSize: '13px',
        fontFamily: 'Nunito',
      }
    }
  },
  dataLabels: {
    enabled: false
  },
  stroke: {
    curve: 'smooth'
  },
  legend: {
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Nunito',
    markers: {
      size: 8,
      shape: 'circle'
    }
  },
}

