export const SHIFT_DAYS = [
  {
    id: 0,
    name: 'Mo',
    key: 'is_monday_shift'
    },
    {
      id: 1,
      name: 'Tu',
      key: 'is_tuesday_shift'
    },
    {
      id: 2,
      name: 'We',
      key: 'is_wednesday_shift'
    },
    {
      id: 3,
      name: 'Th',
      key: 'is_thursday_shift'
    },
    {
      id: 4,
      name: 'Fr',
      key: 'is_friday_shift'
    },
    {
      id: 5,
      name: 'Sa',
      key: 'is_saturday_shift'
    },
    {
    id: 6,
    name: 'Su',
    key: 'is_sunday_shift'
  },
];
