<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useProductivityDashboardStore } from '@/stores/productivity-dashboard';
import NoDataAvailable from '../common/NoDataAvailable.vue';

const productivityDashboardStore = useProductivityDashboardStore()
const { loading, activeAgentsRateTrendOptions, activeAgentsRateTrendSeries } = storeToRefs(productivityDashboardStore)

</script>

<template>
    <section class="tw-flex tw-flex-col tw-gap-y-6">
        <h3 class="tw-mr-auto tw-w-full tw-text-left tw-text-lg tw-font-black tw-text-shyftoff-purple">
            Active Agent Rate Trend
        </h3>

        <template v-if="loading">
            <v-skeleton-loader class="tw-h-[500px] tw-w-full tw-rounded-lg" />
        </template>

        <apexchart v-else-if="activeAgentsRateTrendSeries.length && !loading" width="100%" height="500"
            :options="activeAgentsRateTrendOptions" :series="activeAgentsRateTrendSeries" />

        <template v-else>
            <NoDataAvailable />
        </template>
    </section>
</template>