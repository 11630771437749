import { ref } from 'vue';
import axios from 'axios';
import { useOnboardingStore } from '@/stores/onboarding';
import type { HyperKycResult } from '@/types/hyperverge';

const API_BASE_URL = import.meta.env.VITE_API_URL
const HYPERVERGE_SDK = import.meta.env.VITE_HYPERVERGE_SDK

export const useHyperverge = () => {
    const loadingHyperverge = ref(false);
    const { setOnbordingError } = useOnboardingStore();

    const generateToken = async (qualification_id: number, workflow: string, customHandler: (result: HyperKycResult) => void) => {
        try {
            loadingHyperverge.value = true;

            const response = await axios.get(`${API_BASE_URL}/api/qualifications/hyperverge/generate_token?qualification_id=${qualification_id}`);
            starOnboarding(response.data.access_token, response.data.transaction_id, workflow, customHandler);
        } catch (error) {
            setOnbordingError('There was an error initializing the verifier.');
            loadingHyperverge.value = false;
        }
    };

    const loadHyperVergeScript = () => {
        const script = document.createElement('script');
        script.src = HYPERVERGE_SDK;

        document.head.appendChild(script);
    };

    const starOnboarding = (accessToken: string, transaction_id: string, workflow: string, customHandler: (result: HyperKycResult) => void) => {
        //@ts-ignore
        const hyperKycConfig = new HyperKycConfig(
            accessToken,
            workflow,
            transaction_id,
        );

        //@ts-ignore
        HyperKYCModule.launch(hyperKycConfig, customHandler);
    };

    return { generateToken, loadHyperVergeScript, loadingHyperverge };
}