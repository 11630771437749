<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { usePerformanceDashboardStore } from '@/stores/performance-dashboard';

const performanceDashboardStore = usePerformanceDashboardStore()
const { loading, performanceSeries, performanceChartsOptions } = storeToRefs(performanceDashboardStore)

</script>

<template>
    <section class="tw-flex tw-w-full tw-flex-col tw-gap-6 lg:tw-flex-row lg:tw-flex-wrap">
        <template v-if="loading">
            <v-skeleton-loader class="tw-h-[500px] tw-w-full tw-rounded-lg" />
        </template>

        <template v-else-if="performanceSeries.length && !loading" v-for="(metric, index) in performanceSeries"
            :key="index">
            <section class="tw-flex tw-flex-1 tw-flex-col tw-gap-y-6 lg:tw-min-w-[45%]">
                <h3 class="tw-mr-auto tw-w-full tw-text-left tw-text-lg tw-font-black tw-text-shyftoff-purple">
                    {{ metric.name }} vs Target
                </h3>

                <apexchart v-if="metric.series.length && !loading" width="100%" height="500"
                    :options="performanceChartsOptions" :series="metric.series" />

                <template v-else>
                    <h5 class="tw-my-6 tw-mt-8 tw-text-center tw-text-lg tw-font-extrabold tw-text-darker-light-text">
                        No data available.
                    </h5>
                    <img src="@/assets/images/empty-state--tables.svg"
                        class="tw-relative tw-left-1/2 tw-block !tw-min-h-[160px] tw-max-w-full -tw-translate-x-1/2 tw-scale-90 tw-object-cover sm:!tw-min-h-[270px]"
                        alt="">
                </template>
            </section>
        </template>

        <template v-else>
            <h5 class="tw-m-auto tw-text-center tw-text-lg tw-font-extrabold tw-text-darker-light-text">
                No data available.
            </h5>
            <img src="@/assets/images/empty-state--tables.svg"
                class="tw-relative tw-left-1/2 tw-block !tw-min-h-[160px] tw-max-w-full -tw-translate-x-1/2 tw-scale-90 tw-object-cover sm:!tw-min-h-[270px]"
                alt="">
        </template>
    </section>
</template>