import { defineStore } from 'pinia'
import { ref, reactive, watch } from 'vue'
import type {
    AgentProductivity,
    CampaignAgentCountHistory,
    WithCountTableData,
    ProductivityDashboard,
    ProductivityDashboardSummary,
    ProductivityOverview,
    TableOptions,
    TableQueryParams,
    TableState,
} from '@/types/dashboard'
import type { Campaign } from '@/types/campaign'
import useHelpers from '@/composables/useHelpers'
import axios, { isAxiosError } from 'axios'
import type { Series } from '@/types/charts'
import { trend_chart_options } from '@/plugins/apexcharts/line_chart_options'

const apiBaseUrl = import.meta.env.VITE_API_URL

export const useProductivityDashboardStore = defineStore('productivity_dashboard', () => {
    const { errorHelper } = useHelpers()

    const loading = ref(false)
    const selectedDateGroup = ref('daily')
    const selectedCampaign = ref<Campaign>()

    const productivityData = ref<ProductivityOverview[]>([])

    const productivityOverviewChartSeries = ref<Series[]>([])
    const productivityIntraDaySeries = ref<Series[]>([])

    const agentCountTrendLabels = ref<string[]>([])
    const agentCountProductionCIPSeries = ref<Series[]>([])
    const agentCountAddsRemovalsNetSeries = ref<Series[]>([])

    const activeAgentsRateTrendOptions = ref()
    const activeAgentsRateTrendSeries = ref<Series[]>([])

    const agentsProductivity = reactive<TableState<AgentProductivity>>({
        agents: [],
        total: 0,
        page: 1,
        itemsPerPage: 10,
        loading: false
    })

    const productivityIntraDay = reactive<TableState<ProductivityOverview>>({
        agents: [],
        total: 0,
        page: 1,
        itemsPerPage: 10,
        loading: false
    })

    const summary = reactive<ProductivityDashboardSummary>({
        active_agents_rate: 0,
        hours_per_active_agent: 0,
        percentage_locked: 0
    })

    function filterDataByDateGroup(data: ProductivityOverview[], dateGroup: string): ProductivityOverview[] {
        if (!data.length) return [];

        const endDate = new Date();
        endDate.setDate(endDate.getDate() - 1);
        endDate.setHours(23, 59, 59, 999);

        const startDate = new Date(endDate);
        startDate.setHours(0, 0, 0, 0);

        switch (dateGroup) {
            case 'monthly':
                startDate.setDate(endDate.getDate() - 29);
                break;
            case 'weekly':
                startDate.setDate(endDate.getDate() - 6);
                break;
            case 'daily':
                break;
            default:
                break;
        }

        return data.filter(item => {
            const itemDate = new Date(item.report_date);
            return itemDate >= startDate && itemDate <= endDate;
        });
    }

    function parseProductivityData(
        data: ProductivityOverview[],
        filterByDateGroup = false
    ): Series[] {
        if (!data.length) return [];

        const dataToProcess = filterByDateGroup
            ? filterDataByDateGroup(data, selectedDateGroup.value)
            : data;

        if (!dataToProcess.length) return [];

        const length = dataToProcess.length;
        const actualHrs = new Array(length);
        const releasedHrs = new Array(length);
        const scheduledHrs = new Array(length);
        const lockedHrs = new Array(length);

        for (let i = 0; i < length; i++) {
            const item = dataToProcess[i];
            const timestamp = new Date(item.report_date).getTime();
            const round = (val: number) => val ? ~~(val * 100) / 100 : 0;

            actualHrs[i] = [timestamp, round(item.actual_hrs)];
            releasedHrs[i] = [timestamp, round(item.released_hrs)];
            scheduledHrs[i] = [timestamp, round(item.scheduled_hrs)];
            lockedHrs[i] = [timestamp, round(item.locked_hrs)];
        }

        return [
            { name: 'actual_hrs', data: actualHrs, type: 'column', color: '#5723B4' },
            { name: 'released_hrs', data: releasedHrs, type: 'line', color: '#8C8CE8' },
            { name: 'scheduled_hrs', data: scheduledHrs, type: 'line', color: '#EB74C4' },
            { name: 'locked_hrs', data: lockedHrs, type: 'line', color: '#2B2E41' }
        ];
    }

    function parseActiveAgentsRateTrendData(data: { date: string, active_agents_rate: number }[]): Series[] {
        if (!data.length) return []

        activeAgentsRateTrendOptions.value = {
            ...trend_chart_options,
            labels: data.map(item => item.date) || [],
            dataLabels: {
                enabled: true,
                enabledOnSeries: [0],
                formatter: function (val: number) {
                    return val + '%'
                }
            },
        }

        return [
            {
                name: 'Agent Rate',
                data: data.map(item => Number((item.active_agents_rate * 100).toFixed(0))),
                type: 'line',
                color: '#8C8CE8'
            }
        ]


    }

    function parseAgentCountTrendData(data: CampaignAgentCountHistory) {
        const { production_agents, certification_in_progress_agents } = data;

        const labels: string[] = [];
        const productionData: number[] = [];
        const cipData: number[] = [];
        const addsData: number[] = [];
        const removalsData: number[] = [];
        const netData: number[] = [];

        for (let i = 0; i < production_agents.length; i++) {
            labels.push(production_agents[i].report_date);
            productionData.push(production_agents[i].count);
            cipData.push(certification_in_progress_agents[i].count);

            addsData.push(production_agents[i].adds ?? 0);
            removalsData.push(production_agents[i].removals ?? 0);
            netData.push(production_agents[i].net_change ?? 0);
        }

        agentCountTrendLabels.value = labels

        if (production_agents.length && certification_in_progress_agents.length) {
            agentCountProductionCIPSeries.value = [
                { name: 'Production', data: productionData, color: '#EB74C4' },
                { name: 'CIP', data: cipData, color: '#8C8CE8' },
            ]
        }

        if (production_agents.length) {
            agentCountAddsRemovalsNetSeries.value = [
                { name: 'Adds to Production', data: addsData, type: 'line', color: '#5723B4' },
                { name: 'Removals from Production', data: removalsData, type: 'line', color: '#8E95A4' },
                { name: 'Net', data: netData, type: 'line', color: '#EB74C4' },
            ]
        }
    }

    async function getProductivityDashboardData(campaign_id: number) {
        try {
            const { data } = await axios.get<ProductivityDashboard>(
                `${apiBaseUrl}/api/dashboards/by_campaign/${campaign_id}/productivity?limit=10&with_count=true`
            )
            return data
        } catch (error) {
            if (isAxiosError(error)) {
                errorHelper(error?.response?.data)
            }
        }
    }

    async function getAgentsProductivityTableData({
        id,
        offset,
        limit,
        sort_by,
        sort_order
    }: TableQueryParams) {
        try {
            const { data } = await axios.get<
                WithCountTableData<AgentProductivity>
            >(
                `${apiBaseUrl}/api/dashboards/by_campaign/${id}/productivity/intra_agents`,
                {
                    params: {
                        limit,
                        offset,
                        with_count: true,
                        sort_by,
                        sort_order
                    }
                }
            )
            return data
        } catch (error) {
            if (isAxiosError(error)) {
                errorHelper(error?.response?.data)
            }
        }
    }

    async function getAgentsProductivityIntraDayTableData(id: number) {
        try {
            const { data } = await axios.get<ProductivityOverview[]>(
                `${apiBaseUrl}/api/dashboards/by_campaign/${id}/productivity/intra_day`
            )
            return data
        } catch (error) {
            if (isAxiosError(error)) {
                errorHelper(error?.response?.data)
            }
        }
    }

    async function fetchProductivityData() {
        if (!selectedCampaign.value) return

        try {
            loading.value = true

            const [dashboardData, intraDayData] = await Promise.all([
                getProductivityDashboardData(selectedCampaign.value.id),
                getAgentsProductivityIntraDayTableData(selectedCampaign.value.id)
            ]);

            if (!dashboardData) {
                throw new Error('No dashboard data received');
            }

            if (!intraDayData) {
                throw new Error('No intra day data received');
            }

            productivityData.value = dashboardData.productivity_overview || []

            summary.active_agents_rate = dashboardData.summary.active_agents_rate
            summary.hours_per_active_agent = dashboardData.summary.hours_per_active_agent
            summary.percentage_locked = dashboardData.summary.percentage_locked

            agentsProductivity.agents = dashboardData.agents_productivity.results
            agentsProductivity.total = dashboardData.agents_productivity.count

            productivityIntraDay.agents = intraDayData || []
            productivityIntraDay.total = intraDayData?.length || 0

            productivityIntraDaySeries.value = parseProductivityData(intraDayData || [])

            parseAgentCountTrendData(dashboardData.campaign_agent_count_history)
            activeAgentsRateTrendSeries.value = parseActiveAgentsRateTrendData(dashboardData.active_agents_rate_trend)
            productivityOverviewChartSeries.value = parseProductivityData(dashboardData.productivity_overview || [], true)
        } catch (error) {
            console.error('Error fetching dashboard data:', error)
        } finally {
            loading.value = false
        }
    }

    async function handleTableUpdate(options: TableOptions) {
        if (!selectedCampaign.value) return

        const state = agentsProductivity
        state.loading = true

        try {
            const params: any = {
                id: selectedCampaign.value.id,
                offset: (options.page - 1) * options.itemsPerPage,
                limit: options.itemsPerPage
            }

            if (options.sortBy?.[0]) {
                params.sort_by = options.sortBy[0].key
                params.sort_order = options.sortBy[0].order
            }

            const data = await getAgentsProductivityTableData(params)

            if (data) {
                state.agents = data.results
                state.total = data.count
                state.page = options.page
                state.itemsPerPage = options.itemsPerPage
            }
        } catch (error) {
            console.error(`Error updating table:`, error)
        } finally {
            state.loading = false
        }
    }

    function resetState() {
        loading.value = false;
        selectedDateGroup.value = 'daily';
        productivityData.value = [];

        Object.assign(summary, {
            active_agents_rate: 0,
            hours_per_active_agent: 0,
            percentage_locked: 0
        });

        productivityOverviewChartSeries.value = [];
        productivityIntraDaySeries.value = [];
        agentCountProductionCIPSeries.value = [];
        agentCountAddsRemovalsNetSeries.value = [];
        activeAgentsRateTrendSeries.value = [];
        agentCountTrendLabels.value = [];
    }

    watch(selectedCampaign, () => {
        resetState()
        fetchProductivityData()
    })

    watch(selectedDateGroup, () => {
        if (productivityData.value.length) {
            productivityOverviewChartSeries.value = parseProductivityData(productivityData.value, true);
        }
    });

    return {
        loading,
        selectedDateGroup,
        selectedCampaign,
        summary,
        agentsProductivity,
        productivityOverviewChartSeries,
        agentCountProductionCIPSeries,
        agentCountAddsRemovalsNetSeries,
        activeAgentsRateTrendSeries,
        activeAgentsRateTrendOptions,
        agentCountTrendLabels,
        productivityIntraDay,
        productivityIntraDaySeries,
        resetState,
        fetchProductivityData,
        handleTableUpdate
    }
})
