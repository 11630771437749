import { defineStore } from 'pinia'
import { ref, reactive, watch } from 'vue'
import type {
    PerformanceDashboard,
    PriorityBoardingAgent,
    PriorityBoardingData,
    TableHeader,
    TableOptions,
    TableQueryParams,
    TableState,
} from '@/types/dashboard'
import type { Campaign } from '@/types/campaign'
import useHelpers from '@/composables/useHelpers'
import axios, { isAxiosError } from 'axios'
import type { Series, SeriesType } from '@/types/charts'
import { trend_chart_options } from '@/plugins/apexcharts/line_chart_options'
import { useDebounceFn } from '@vueuse/core'

const apiBaseUrl = import.meta.env.VITE_API_URL

type PerformanceMetricsSeries = {
    name: string
    series: Series[]
}

export const usePerformanceDashboardStore = defineStore('performance_dashboard', () => {
    const { errorHelper } = useHelpers()

    const loading = ref(false)
    const pbSearchQuery = ref('')
    const selectedCampaign = ref<Campaign>()

    const performanceChartsOptions = ref()
    const performanceSeries = ref<PerformanceMetricsSeries[]>([])
    const pb_count_summary = ref<{ [key: string]: { count: number; percentage: number } } | null>(null)

    const priorityBoardingTableHeaders = ref<TableHeader[]>([])
    const priorityBoarding = reactive<TableState<PriorityBoardingAgent>>({
        agents: [],
        total: 0,
        page: 1,
        itemsPerPage: 10,
        loading: false,
        loadingSearch: false
    })

    function parsePerformanceData(data: PerformanceDashboard) {
        const { performance_metrics } = data

        const metrics = Object.keys(performance_metrics)
        const performance_series = metrics.map(metric => {
            const formattedName = metric
                .split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')

            const hasData = performance_metrics[metric].length > 0;
            const hasTarget = performance_metrics[metric].some(item => item.target !== null);

            const series: Series[] = [];

            if (hasData) {
                series.push({
                    name: formattedName,
                    data: performance_metrics[metric].map(item => Number(item.metric).toFixed(2)),
                    type: 'line' as SeriesType,
                    color: '#8C8CE8'
                });
            }

            if (hasTarget) {
                series.push({
                    name: 'Min Target',
                    data: performance_metrics[metric].map(item => Number(item.target[0]).toFixed(2)),
                    type: 'line' as SeriesType,
                    color: '#5723B4'
                });
                
                series.push({
                    name: 'Max Target',
                    data: performance_metrics[metric].map(item => Number(item.target[1]).toFixed(2)),
                    type: 'line' as SeriesType,
                    color: '#EB74C4'
                });
            }

            return {
                name: formattedName,
                series
            }
        })

        performanceChartsOptions.value = {
            ...trend_chart_options,
            tooltip: {
                y: {
                    show: true,
                    formatter: function (val: number) {
                        return (val * 100).toFixed(0) + '%'
                    }
                }
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [0],
                formatter: function (val: number) {
                    return (val * 100).toFixed(0) + '%'
                }
            },
            labels: performance_metrics[metrics[0]].map(item => item.date) || [],
        }

        return performance_series
    }

    function setPriorityBoardingTableHeaders(data: PriorityBoardingAgent) {
        const metrics = data.metrics

        const headerOrder = [
            'reliability',
            'unscheduled_hours_percent',
            'quality',
            'efficiency',
            'momentum'
        ];

        const headers: TableHeader[] = [
            {
                key: 'agent',
                title: 'Agent',
                sortable: false
            },
            {
                key: 'campaign_application',
                title: 'Applications',
                sortable: false
            },
            {
                key: 'shyftoff_id',
                title: 'Shyftoff ID',
                sortable: false
            },
            ...headerOrder.map(key => ({
                key: key,
                title: metrics[key].name,
                sortable: false
            })),
            {
                key: 'priority_boarding_group',
                title: 'PB Group',
                sortable: false
            }
        ];

        priorityBoardingTableHeaders.value = headers
    }

    async function getPerformanceDashboardData(id: number) {
        try {
            const { data } = await axios.get<PerformanceDashboard>(
                `${apiBaseUrl}/api/dashboards/by_campaign/${id}/performance`
            )
            return data
        } catch (error) {
            if (isAxiosError(error)) {
                errorHelper(error?.response?.data)
            }
        }
    }

    async function getPriotityBoardingData({ id, offset, limit, search }: TableQueryParams) {
        try {
            const { data } = await axios.get<PriorityBoardingData>(
                `${apiBaseUrl}/api/campaigns/${id}/pb-stats`,
                {
                    params: {
                        limit,
                        offset,
                        search,
                        with_count: true,
                    }
                }
            )
            return data
        } catch (error) {
            if (isAxiosError(error)) {
                errorHelper(error?.response?.data)
            }
        }
    }

    async function fetchPerformanceData() {
        if (!selectedCampaign.value) return

        try {
            loading.value = true
            const performance_data = await getPerformanceDashboardData(selectedCampaign.value.id)

            if (performance_data) {
                performanceSeries.value = parsePerformanceData(performance_data)
            }

            const pb_data = await getPriotityBoardingData({ id: selectedCampaign.value.id, offset: 0, limit: 10 })

            if (pb_data) {
                priorityBoarding.agents = pb_data.results
                priorityBoarding.total = pb_data.count
                pb_count_summary.value = pb_data.pb_metrics

                setPriorityBoardingTableHeaders(pb_data.results[0])
            }
        } catch (error) {
            console.error('Error fetching dashboard data:', error)
        } finally {
            loading.value = false
        }
    }

    const debouncedSearch = useDebounceFn(async (
        campaignId: number,
        searchQuery: string
    ) => {
        try {
            priorityBoarding.loadingSearch = true;

            const pb_data = await getPriotityBoardingData({
                id: campaignId,
                offset: 0,
                limit: 10,
                search: searchQuery
            });

            if (pb_data) {
                priorityBoarding.agents = pb_data.results;
                priorityBoarding.total = pb_data.count;
                setPriorityBoardingTableHeaders(pb_data.results[0]);
            }
        } catch (error) {
            console.error('Error searching agent:', error);
        } finally {
            priorityBoarding.loadingSearch = false;
        }
    }, 1000);

    async function searchAgent() {
        if (!selectedCampaign.value) return;

        await debouncedSearch(
            selectedCampaign.value.id,
            pbSearchQuery.value
        );
    }

    async function handleTableUpdate(options: TableOptions) {
        if (!selectedCampaign.value) return

        const state = priorityBoarding
        state.loading = true

        try {
            const params: TableQueryParams = {
                id: selectedCampaign.value.id,
                offset: (options.page - 1) * options.itemsPerPage,
                limit: options.itemsPerPage,
                search: pbSearchQuery.value
            }

            if (options.sortBy?.[0]) {
                params.sort_by = options.sortBy[0].key
                params.sort_order = options.sortBy[0].order
            }

            const data = await getPriotityBoardingData(params)

            if (data) {
                state.agents = data.results
                state.total = data.count
                state.page = options.page
                state.itemsPerPage = options.itemsPerPage
            }
        } catch (error) {
            console.error(`Error updating table:`, error)
        } finally {
            state.loading = false
        }
    }

    function reset() {
        loading.value = false
        priorityBoarding.agents = []
        priorityBoarding.total = 0
        pb_count_summary.value = null
        priorityBoardingTableHeaders.value = []
        performanceSeries.value = []
    }

    watch(selectedCampaign, () => {
        reset()
        fetchPerformanceData()
    })

    return {
        loading,
        pbSearchQuery,
        selectedCampaign,
        pb_count_summary,
        priorityBoarding,
        performanceSeries,
        performanceChartsOptions,
        priorityBoardingTableHeaders,
        reset,
        handleTableUpdate,
        fetchPerformanceData,
        searchAgent
    }
})
