<script setup lang="ts">
import IntraAgentProductivityTable from '@/components/productivity-dashboard/intra-day/IntraAgentProductivityTable.vue';
import IntraDayProductivityTable from './IntraDayProductivityTable.vue';
import { productivity_chart_options } from '@/plugins/apexcharts/mixed_bar_line_options';
import { storeToRefs } from 'pinia';
import { useProductivityDashboardStore } from '@/stores/productivity-dashboard';
import NoDataAvailable from '@/components/common/NoDataAvailable.vue';

const productivityDashboardStore = useProductivityDashboardStore()
const {
    loading,
    productivityIntraDay,
    productivityIntraDaySeries
} = storeToRefs(productivityDashboardStore)

const productivityIntraDayChartOptions = {
    ...productivity_chart_options,
    xaxis: {
        type: 'datetime',
        labels: {
            show: true,
            style: {
                fontSize: '13px',
                fontFamily: 'Nunito',
            },
            formatter: function (value: string, timestamp: number) {
                return new Date(timestamp).toLocaleString('en-US', {
                    day: '2-digit',
                    month: 'short',
                });
            },
            offsetX: 10,
        },
        tooltip: {
            enabled: false
        },
    },
}
</script>

<template>
    <section class="tw-flex tw-flex-col tw-gap-y-6">
        <h3 class="tw-text-lg tw-font-black tw-text-shyftoff-purple">
            Intra-Day Performance
        </h3>

        <v-card class="tw-flex tw-flex-col tw-gap-y-12 !tw-p-10">
            <div class="tw-flex tw-flex-col tw-gap-y-6">
                <div v-if="loading">
                    <VSkeletonLoader type="table-thead, table-row-divider@7" />
                </div>

                <template v-else>
                    <template v-if="productivityIntraDay && productivityIntraDay.agents.length > 0">
                        <apexchart ref="chart" width="100%" height="500" :options="productivityIntraDayChartOptions"
                            :series="productivityIntraDaySeries" />
                    </template>
                    <template v-else>
                        <NoDataAvailable message="No Intra-Day data available." />
                    </template>


                    <IntraDayProductivityTable />
                </template>
            </div>

            <IntraAgentProductivityTable />
        </v-card>
    </section>
</template>

<style scoped></style>