<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue'
import { useHead } from '@vueuse/head'
import { useRoute } from 'vue-router'
import useCampaigns from '@/composables/useCampaigns'
import { useCampaignStore } from '@/stores/campaigns'
import type { CampaignApplication } from '@/types/campaign-applications'
import HoursWidget from './HoursWidget.vue'
import { useHyperverge } from '@/composables/useHyperverge'
import type { HyperKycResult } from '@/types/hyperverge'

import { useForm } from 'vee-validate';
import { toTypedSchema } from '@vee-validate/zod';
import * as z from 'zod';
import { SHIFT_DAYS } from '@/constants/campaigns'
import { campaignApplicationQuestionsSchema } from '@/plugins/zod/campaign_application_questions_schemas'

useHead({
  htmlAttrs: {
    class: 'tw-overflow-hidden',
  }
})

type FormValues = {
  policyAgreement: boolean | null
  pdfResume: File | File[]
  [key: string]: any
}

const route = useRoute()
const props = defineProps(['campaign'])
const emit = defineEmits(['closeDrawer'])

const expanded = ref(false)
const loadingState = ref(false)
const transitionFinished = ref(true)
const dynamicFields = ref<Record<string, any>>({});
const tokenizedFields = ref<Record<string, { show: boolean }>>({})
const schema = ref(toTypedSchema(campaignApplicationQuestionsSchema));
const verificationStatuses = ref<Record<string, {
  completed: boolean,
  show: boolean
}>>({});

const campaignStore = useCampaignStore()
const { getUserCampaignApplications, sendResume } = useCampaigns()
const { generateToken, loadHyperVergeScript, loadingHyperverge } = useHyperverge()
const { applyToCampaign, getCampaignApplicationQuestions, applicationQuestions } = useCampaigns()

const currentCampaignApplication = computed(() => {
  return campaignStore.getCampaignApplications.find((application: CampaignApplication) => application.campaign.id === props.campaign.id)
})

const { defineField, handleSubmit, errors, validateField, setFieldValue } = useForm<FormValues>({
  validationSchema: computed(() => schema.value),
  initialValues: {
    policyAgreement: null,
    pdfResume: []
  }
});

const [policyAgreement, policyAgreementProps] = defineField('policyAgreement');
const [pdfResume, pdfResumeProps] = defineField('pdfResume');

const onSubmit = handleSubmit(async (formValues: FormValues) => {
  loadingState.value = true;

  try {
    const dynamicQuestionsAnswers = Object.entries(formValues)
      .filter(([key]) => key !== 'pdfResume' && key !== 'policyAgreement')
      .map(([questionId, answer]) => ({
        question_id: parseInt(questionId),
        answer
      }));

    const response = await applyToCampaign({
      id: props.campaign.id,
      policyAgreement: formValues.policyAgreement ?? false,
      answers: dynamicQuestionsAnswers
    });

    if (response != null && formValues.pdfResume) {
      await sendResume({
        file: Array.isArray(formValues.pdfResume) ? formValues.pdfResume[0] : formValues.pdfResume,
        id: response.id
      });
    }

    await getUserCampaignApplications();
    emit('closeDrawer');
  } finally {
    loadingState.value = false;
  }
});

function toggleDescription() {
  transitionFinished.value = false
  expanded.value = !expanded.value
  if (!expanded.value) {
    setTimeout(() => {
      transitionFinished.value = true
    }, 300)
  }
}

async function handler(HyperKycResult: HyperKycResult, questionId: number) {
  if (HyperKycResult.status !== 'error' && HyperKycResult.status !== 'user_cancelled') {
    verificationStatuses.value[questionId] = {
      completed: true,
      show: true
    };

    setFieldValue(questionId.toString(), true);
    await validateField(questionId.toString());
  }

  loadingHyperverge.value = false;
};

onBeforeMount(async () => {
  loadHyperVergeScript()

  await getCampaignApplicationQuestions(props.campaign.id)

  const dynamicValidations: Record<string, z.ZodTypeAny> = {};

  applicationQuestions.value.forEach((question) => {
    const fieldId = question.id.toString();

    switch (question.answer_type) {
      case 1: { // Boolean
        dynamicValidations[fieldId] = z.boolean({
          required_error: 'You must select an option'
        }).nullable()
          .refine(val => val !== null, {
            message: 'You must select an option'
          });
        const [field, props] = defineField(fieldId);
        dynamicFields.value[fieldId] = { field, props };
        break;
      }

      case 2: { // Text
        dynamicValidations[fieldId] = z.string({
          required_error: 'This field is required'
        });
        const [field, props] = defineField(fieldId);
        dynamicFields.value[fieldId] = { field, props };
        break;
      }

      case 3: { // SSN
        dynamicValidations[fieldId] = z.string({
          required_error: 'This field is required'
        }).regex(/^\d{3}-\d{2}-\d{4}$/, 'Must be in format XXX-XX-XXXX');
        const [field, props] = defineField(fieldId);
        dynamicFields.value[fieldId] = { field, props };
        tokenizedFields.value[fieldId] = { show: false };
        break;
      }

      case 4: { // Verification
        verificationStatuses.value[fieldId] = {
          completed: false,
          show: false
        };

        dynamicValidations[fieldId] = z.custom(() => {
          return verificationStatuses.value[fieldId]?.completed;
        }, {
          message: 'Verification must be completed'
        });

        const [field, props] = defineField(fieldId);
        dynamicFields.value[fieldId] = { field, props };
        break;
      }
    }
  });

  schema.value = toTypedSchema(z.object({
    ...campaignApplicationQuestionsSchema.shape,
    ...dynamicValidations
  }));
})

</script>

<template>
  <v-card
    class="!tw-fixed tw-right-0 tw-top-0 !tw-z-10 tw-h-screen tw-w-1/2 tw-min-w-[1100px] !tw-overflow-y-auto !tw-rounded-b-none !tw-rounded-r-none !tw-border-none !tw-p-0 !tw-pt-0">
    <v-card-title v-if="route.name !== 'CampaignDetailsPage'"
      class="tw-sticky tw-left-0 tw-top-0 tw-z-10 !tw-grid tw-grid-cols-12 tw-bg-white !tw-p-0">
      <div
        class="tw-col-span-8 -tw-mr-6 tw-border-b tw-border-solid tw-border-b-[#E8E8EA] tw-bg-white tw-px-4 tw-py-4 tw-pr-6">
        <div class="tw-flex tw-w-full tw-items-center tw-justify-between">
          <v-btn @click="() => $emit('closeDrawer')" color="primary" size="large" flat rounded="circle" variant="plain"
            width="36px" height="36px" min-width="0" class="tw-p-0">
            <svg width="14" height="24" viewBox="0 0 14 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13.4167 2.59513L11.3817 0.566895L0 11.9112L11.3817 23.2556L13.4167 21.2273L4.06984 11.9112L13.4167 2.59513Z"
                fill="#5723B4" />
            </svg>
          </v-btn>
          <router-link :to="`/campaigns/${props.campaign.id}`"
            class="!tw-text-base tw-font-bold tw-leading-normal tw-text-shyftoff-purple hover:tw-underline"
            target="_blank">
            <svg class="tw-mr-2 tw-inline-block tw-align-middle" width="16" height="16" viewBox="0 0 16 16" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.16667 0.5C1.25578 0.5 0.5 1.25578 0.5 2.16667V13.8333C0.5 14.7442 1.25578 15.5 2.16667 15.5H13.8333C14.7442 15.5 15.5 14.7442 15.5 13.8333V8H13.8333V13.8333H2.16667V2.16667H8V0.5H2.16667ZM9.66667 0.5V2.16667H12.6549L4.91081 9.91081L6.08919 11.0892L13.8333 3.34505V6.33333H15.5V0.5H9.66667Z"
                fill="#5723B4" />
            </svg>
            Open campaign in new window
          </router-link>
        </div>
      </div>
    </v-card-title>

    <v-card-text class="!tw-px-0 !tw-pb-0 !tw-pl-6 !tw-text-base">
      <Teleport to="body" v-if="route.name !== 'CampaignDetailsPage'">
        <div class="tw-fixed tw-left-0 tw-top-0 tw-z-0 tw-h-full tw-w-full tw-bg-[rgba(93,65,140,0.3)]"></div>
      </Teleport>

      <div class="tw-my-5 tw-grid tw-grid-cols-12 tw-gap-7 tw-bg-white">
        <v-form @submit="onSubmit" class="tw-relative tw-z-0 tw-col-span-8 tw-text-regular-text">
          <div class="tw-mb-8">
            <h3 v-if="$route.name !== 'CampaignDetailsPage'"
              class="tw-mb-6 tw-text-xl tw-font-extrabold tw-text-shyftoff-purple">
              {{ props.campaign.name }}
            </h3>
            <div>
              <div class="tw-max-h-[72px] tw-overflow-hidden tw-transition-all tw-duration-300"
                :class="{ '!tw-max-h-[700px]': expanded }">
                <div class="tw-space-y-4"
                  :class="{ 'tw-line-clamp-none': expanded, 'tw-line-clamp-2': transitionFinished === true }">
                  <p>{{ props.campaign.description }}</p>
                </div>
              </div>
              <span v-if="campaign.description" @click.prevent.stop="toggleDescription"
                class="tw-cursor-pointer tw-font-extrabold tw-text-shyftoff-pink">
                {{ expanded ? 'less' : 'more' }}
              </span>
            </div>
          </div>

          <div>
            <div
              class="tw-mb-8 tw-h-[192px] tw-overflow-auto tw-rounded-lg tw-border tw-border-grey-border tw-bg-gray-100 tw-p-4">
              <div class="tw-mb-4 tw-mt-2 tw-flex tw-flex-col tw-gap-x-4 tw-pl-4 tw-text-left">
                <!-- <img src="@/assets/images/logo-shyftoff--purple.svg" class="-tw-ml-3 tw-inline-block tw-h-9 tw-max-w-none" />s -->
                <div class="tw-text-md tw-font-black tw-text-regular-text">
                  <h4 class="tw-text-md tw-inline-block">Campaign Policies</h4>&nbsp;
                  <RouterLink to="/campaign-policies"
                    class="tw-text-sm tw-font-normal tw-text-shyftoff-purple hover:tw-underline">(
                    <svg class="tw-mr-1 tw-inline-block tw-align-middle" width="16" height="16" viewBox="0 0 16 16"
                      fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path data-v-17aadacc=""
                        d="M2.16667 0.5C1.25578 0.5 0.5 1.25578 0.5 2.16667V13.8333C0.5 14.7442 1.25578 15.5 2.16667 15.5H13.8333C14.7442 15.5 15.5 14.7442 15.5 13.8333V8H13.8333V13.8333H2.16667V2.16667H8V0.5H2.16667ZM9.66667 0.5V2.16667H12.6549L4.91081 9.91081L6.08919 11.0892L13.8333 3.34505V6.33333H15.5V0.5H9.66667Z"
                        fill="#5723B4"></path>
                    </svg>
                    open in new tab
                    )
                  </RouterLink>
                </div>
              </div>
              <ol class="tw-list-decimal tw-pl-4 tw-text-sm tw-leading-6 tw-text-regular-text">
                <li><b class="tw-font-bold">Schedule Reliability:</b> Schedule Reliability is a calculation of your
                  productive time worked on a campaign divided by the number of hours you scheduled yourself on that
                  same campaign. By picking a schedule you are highly confident you can work, and then working that
                  entire schedule, you will keep your Schedule Reliability score high. The goal is to maintain a 95% or
                  higher Schedule Reliability score. Let's say you scheduled 20 hours and were active in production for
                  19 hours, that would be a 95% Schedule Reliability.</li><br>
                <li><b class="tw-font-bold">No Call Avoidance:</b> When in a productive status, you are expected to be
                  ready and accepting of handling contacts. Examples of call avoidance are not accepting a call when it
                  is offered to you, not responding when a call is connected to you, using hold times in excess,
                  extremely low AHT with high contact counts, and staying in an after call status longer than required
                  for the specific campaign. Call avoidance behaviors can result in being removed from the campaign and
                  ShyftOff.</li><br>
                <li><b class="tw-font-bold">Quiet Workspace:</b> When working on a campaign with ShyftOff, it is
                  expected that you are able to provide a quiet, distraction free work environment. This will allow you
                  to maintain focus and minimize background noise. Examples of impermissible background noise are
                  televisions, music, background conversations, children, pets, and construction. If you are unable to
                  provide a quiet workspace during a shift, you should drop that shift so someone else can work it.</li>
                <br>
                <li><b class="tw-font-bold">Bring Your Own Device:</b> You are expected to supply your own computer,
                  internet and headset that meets the requirements specified on a campaign. Any issues related to your
                  computer, internet and/or headset must be fixed by you. Time in an unproductive status related to
                  these issues is not payable. If you are experiencing a technical issue, you must notify ShyftOff
                  Leadership right away so they are aware, and can help diagnose or troubleshoot when applicable.</li>
                <br>
                <li><b class="tw-font-bold">Campaign Participation:</b> Once in production on a campaign, if you do not
                  complete productive time within a 21 day period you will be moved to an inactive campaign status.
                  Should you wish to resume the campaign, you will be given priority access when a new spot opens.
                  Rejoining the campaign will require a campaign exam to ensure you meet certification requirements.
                </li><br>
                <li><b class="tw-font-bold">Location:</b> ShyftOff only contracts with US based and eligible to work
                  contractors. You are not able to access certification material, or go into production outside of the
                  US. Compliance is of the utmost importance, and operating within the US is a requirement from both
                  ShyftOff and our clients. If you are flagged for working outside the US, that is grounds for removal
                  from the campaign and ShyftOff. Similarly, using a VPN to conceal your location will have the same
                  consequences.</li>
              </ol>
            </div>
          </div>

          <div>
            <p class="tw-whitespace-break-spaces">1. Policy Agreement: Have you read the ShyftOff Campaign Policies
              above and understand that violation of any of the policies can result in removal from the Campaign and/or
              ShyftOff?</p>
            <div>
              <v-radio-group v-model="policyAgreement" v-bind="policyAgreementProps" :error="!!errors.policyAgreement"
                class="tw-mb-6 tw-mt-3 tw-flex tw-w-full tw-items-center tw-justify-between"
                :disabled="currentCampaignApplication">
                <v-radio label="Yes" :value="true" />
                <v-radio label="No" :value="false" />
              </v-radio-group>
            </div>
          </div>

          <!-- PDF Resume -->
          <div>
            <div>
              <p class="tw-inline-block tw-whitespace-break-spaces">
                2. Please attach an updated PDF file of your resume. If the file upload fails, it is likely because it
                is not saved as a PDF.
              </p>
            </div>

            <div class="tw-mb-6 tw-mt-3">
              <v-file-input v-if="!currentCampaignApplication?.resume_url" v-model="pdfResume" v-bind="pdfResumeProps"
                accept=".pdf" class="!tw-pointer-events-auto tw-relative tw-h-[150px] tw-cursor-pointer" flat
                variant="outlined" rounded="lg" prepend-icon="">
                <template #append-inner>
                  <div
                    class="tw-pointer-events-auto tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2 tw-cursor-pointer tw-text-center">
                    <img class="tw-inline-block" src="@/assets/images/pdf-icon.svg" />

                    <div v-if="!pdfResume || pdfResume.length === 0" class="tw-mt-3 tw-leading-3 tw-opacity-70">
                      <p class="tw-font-bold">Select a .pdf file to upload</p>
                      <span class="tw-text-sm tw-text-darker-light-text">
                        or drag and drop it here
                      </span>
                    </div>

                    <div v-else class="tw-mt-3 tw-leading-3">
                      <p class="tw-font-bold">
                        {{ Array.isArray(pdfResume) ? pdfResume[0].name : pdfResume.name }}
                      </p>
                    </div>
                  </div>
                </template>
              </v-file-input>

              <div v-else
                class="tw-relative tw-h-[150px] tw-rounded-lg tw-border tw-border-dashed tw-border-grey-border tw-bg-gray-100">
                <div class="tw-absolute tw-left-1/2 tw-top-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2 tw-text-center">
                  <img class="tw-inline-block" src="@/assets/images/pdf-icon.svg" />
                  <div class="tw-mt-3 tw-leading-3">
                    <p class="tw-font-bold">
                      <a :href="currentCampaignApplication.resume_url"
                        class="tw-text-shyftoff-purple hover:tw-underline" target="_blank">
                        Resume.pdf
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <div v-if="errors.pdfResume" class="tw-mt-2 tw-text-sm tw-text-red-500">
                {{ errors.pdfResume }}
              </div>
            </div>
          </div>

          <!-- Questions -->
          <div v-for="(question, index) in applicationQuestions" :key="question.id">
            <v-alert v-if="verificationStatuses[question.id]?.completed"
              v-model="verificationStatuses[question.id].show" class="tw-mb-6 tw-mt-3" type="info"
              text="Your verification requires further review. This may take a few hours. You can still proceed to apply to the campaign."
              closable />

            <div>
              <p class="tw-inline-block tw-whitespace-break-spaces">
                {{ index + 3 }}. {{ question.question_text }}
              </p>
            </div>

            <div v-if="question.answer_type === 1">
              <v-radio-group v-model="dynamicFields[question.id].field" v-bind="dynamicFields[question.id].props"
                :error="errors[question.id]" class="tw-mb-6 tw-mt-3 tw-flex tw-items-center tw-justify-between"
                :disabled="currentCampaignApplication">
                <v-radio label="Yes" :value="true" />
                <v-radio label="No" :value="false" />
              </v-radio-group>
            </div>

            <div v-if="question.answer_type === 2">
              <v-textarea v-model="dynamicFields[question.id].field" v-bind="dynamicFields[question.id].props"
                :error="errors[question.id]" class="tw-mb-6 tw-mt-3" name="input-7-1" variant="outlined"
                label="Response" auto-grow rounded="lg" :disabled="currentCampaignApplication" />
            </div>

            <div v-if="question.answer_type === 3">
              <v-text-field v-model="dynamicFields[question.id].field" v-bind="dynamicFields[question.id].props"
                :error="errors[question.id]" class="tw-mb-6 tw-mt-3" name="input-ssn" variant="outlined" maxlength="11"
                rounded="lg" placeholder="XXX-XX-XXXX" :type="tokenizedFields[question.id].show ? 'text' : 'password'"
                :append-inner-icon="tokenizedFields[question.id].show ? 'mdi-eye-off' : 'mdi-eye'"
                @click:append-inner="tokenizedFields[question.id].show = !tokenizedFields[question.id].show"
                :disabled="currentCampaignApplication" />
            </div>

            <div class="tw-mb-6 tw-mt-3" v-if="question.answer_type === 4">
              <VBtn v-if="question.qualification"
                @click="generateToken(question.qualification.id, question.qualification.parameters.workflow, (result) => handler(result, question.id))"
                color="primary" variant="outlined" size="large" rounded="lg" type="button"
                :disabled="loadingHyperverge || verificationStatuses[question.id]?.completed || currentCampaignApplication"
                class="tw-capitalize">
                <i v-if="loadingHyperverge"
                  class="mdi mdi-loading tw-mr-2 tw-animate-spin tw-text-2xl tw-leading-none tw-text-white" />
                {{ verificationStatuses[question.id]?.completed ? 'Verification Pending' : 'Start Verification' }}
              </VBtn>

              <div v-if="errors[question.id]" class="tw-mt-2 tw-text-sm tw-text-red-500">
                {{ errors[question.id] }}
              </div>
            </div>
          </div>

          <v-btn type="submit" :loading="loadingState" :disabled="loadingState || !!currentCampaignApplication"
            color="primary" size="large" rounded="lg" class="px-7 tw-mb-6 tw-mt-3 tw-w-full">
            {{ currentCampaignApplication ? 'Already Applied' : 'Apply' }}
          </v-btn>
        </v-form>

        <div class="tw-z-20 tw-col-span-4">
          <v-card class="!tw-sticky tw-top-[-1px] -tw-mt-[90px] tw-h-screen !tw-w-full !tw-rounded-r-none !tw-p-0"
            :class="{ '!tw-h-[calc(100vh_-_83px)]': route.name === 'CampaignDetailsPage' }">
            <v-card-title class="!tw-px-6 !tw-py-6">
              <h3 class="tw-mb-0 tw-text-lg tw-font-extrabold">
                Campaign Details
              </h3>
            </v-card-title>
            <v-card-text class="tw-px-6 !tw-text-base">
              <div class="tw-flex tw-flex-col tw-gap-y-4">
                <div>
                  <span class="tw-mb-3 tw-block tw-text-xs tw-font-bold tw-uppercase tw-text-gray-500">Base agent
                    pay</span>
                  <div class="tw-flex tw-items-center">
                    <div
                      class="tw-relative tw-w-[70px] tw-rounded-md tw-border tw-border-solid tw-border-[#CDE8DE] tw-py-0.5 tw-text-center tw-text-[15px] tw-text-[#249D72]">
                      <small
                        class="tw-absolute -tw-top-2 tw-left-0 tw-bg-white tw-px-[3px] tw-text-[11px] tw-leading-none">min</small>
                      <b class="tw-font-extrabold">${{ props.campaign?.min_hourly_pay ?? 0 }}</b>
                    </div>
                    <div class="tw-mx-2 tw-text-[#249D72]"> - </div>
                    <div
                      class="tw-relative tw-w-[70px] tw-rounded-md tw-border tw-border-solid tw-border-[#CDE8DE] tw-py-0.5 tw-text-center tw-text-[15px] tw-text-[#249D72]">
                      <small
                        class="tw-absolute -tw-top-2 tw-left-0 tw-bg-white tw-px-[3px] tw-text-[11px] tw-leading-none">max</small>
                      <b class="tw-font-extrabold">${{ props.campaign?.max_hourly_pay ?? 0 }}</b>
                    </div>
                  </div>
                </div>
                <div>
                  <span class="tw-mb-3 tw-block tw-text-xs tw-font-bold tw-uppercase tw-text-gray-500">Certification
                    bonus</span>
                  <div
                    class="tw-relative tw-w-[70px] tw-rounded-md tw-border tw-border-dashed tw-border-[#B5D9CC] tw-py-0.5 tw-text-center tw-text-[#249D72]">
                    <b class="tw-text-[15px] tw-font-extrabold">${{ props.campaign?.training_bonus ?
                      props.campaign.training_bonus : 0 }}</b>
                  </div>
                </div>
                <div class="tw-col-span-12">
                  <span class="tw-mb-2 tw-inline-block tw-text-xs tw-font-extrabold tw-uppercase tw-text-gray-500">Shyft
                    days</span>
                  <div v-if="props.campaign" class="tw-flex tw-flex-wrap tw-items-center tw-gap-x-4 tw-gap-y-3">
                    <div v-for="day in SHIFT_DAYS" :key="day.id"
                      class="tw-relative tw-h-10 tw-w-10 tw-rounded-full tw-bg-grey-border tw-p-[1px] before:tw-absolute before:-tw-right-[1.5px] before:tw-top-0 before:tw-z-10 before:tw-block before:tw-rounded-full before:tw-bg-white before:tw-pb-0.5 before:tw-pt-1 before:tw-leading-none before:tw-content-[url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMzUzNTIgMC42NDY0ODRMMC42NDY0ODQgMS4zNTM1Mkw0LjI5Mjk3IDVMMC42NDY0ODQgOC42NDY0OEwxLjM1MzUyIDkuMzUzNTJMNSA1LjcwNzAzTDguNjQ2NDggOS4zNTM1Mkw5LjM1MzUyIDguNjQ2NDhMNS43MDcwMyA1TDkuMzUzNTIgMS4zNTM1Mkw4LjY0NjQ4IDAuNjQ2NDg0TDUgNC4yOTI5N0wxLjM1MzUyIDAuNjQ2NDg0WiIgZmlsbD0iI0MwQzBDMSIvPgo8L3N2Zz4K)]"
                      :class="{ 'tw-bg-[linear-gradient(90deg,_#575EE7_3.71%,_#EF74C0_95.38%)] before:!tw-content-[url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxMyAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjQ1NjQgMS41NTAzOEw0Ljk1NjQxIDkuMDUwMzhDNC45MTI4OCA5LjA5Mzk2IDQuODYxMTggOS4xMjg1MyA0LjgwNDI4IDkuMTUyMTJDNC43NDczNyA5LjE3NTcxIDQuNjg2MzcgOS4xODc4NSA0LjYyNDc3IDkuMTg3ODVDNC41NjMxNyA5LjE4Nzg1IDQuNTAyMTcgOS4xNzU3MSA0LjQ0NTI3IDkuMTUyMTJDNC4zODgzNiA5LjEyODUzIDQuMzM2NjcgOS4wOTM5NiA0LjI5MzEzIDkuMDUwMzhMMS4wMTE4OCA1Ljc2OTEzQzAuOTIzOTI1IDUuNjgxMTcgMC44NzQ1MTIgNS41NjE4OCAwLjg3NDUxMiA1LjQzNzQ5QzAuODc0NTEyIDUuMzEzMSAwLjkyMzkyNSA1LjE5MzggMS4wMTE4OCA1LjEwNTg0QzEuMDk5ODQgNS4wMTc4OSAxLjIxOTEzIDQuOTY4NDcgMS4zNDM1MiA0Ljk2ODQ3QzEuNDY3OTEgNC45Njg0NyAxLjU4NzIxIDUuMDE3ODkgMS42NzUxNiA1LjEwNTg0TDQuNjI0NzcgOC4wNTYwNEwxMS43OTMxIDAuODg3MDk1QzExLjg4MTEgMC43OTkxMzkgMTIuMDAwNCAwLjc0OTcyNSAxMi4xMjQ4IDAuNzQ5NzI1QzEyLjI0OTIgMC43NDk3MjUgMTIuMzY4NSAwLjc5OTEzOSAxMi40NTY0IDAuODg3MDk1QzEyLjU0NDQgMC45NzUwNTIgMTIuNTkzOCAxLjA5NDM1IDEyLjU5MzggMS4yMTg3NEMxMi41OTM4IDEuMzQzMTMgMTIuNTQ0NCAxLjQ2MjQyIDEyLjQ1NjQgMS41NTAzOFoiIGZpbGw9IiNGRjY2QzQiLz4KPC9zdmc+Cg==)] before:!-tw-top-0.5 before:!-tw-right-1 before:!tw-pt-0.5 before:!tw-pb-0': props.campaign[day.key] === true }">
                      <div
                        class="tw-relative tw-h-full tw-w-full tw-rounded-full tw-bg-white tw-text-center tw-font-extrabold tw-leading-10 tw-text-gray-500"
                        :class="{ 'tw-text-shyftoff-purple': props.campaign[day.key] }">
                        <span class="tw-relative tw-z-10">
                          {{ day.name }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <HoursWidget :hoursFrom="props.campaign?.shift_hours_from" :hoursTo="props.campaign?.shift_hours_to" />
                <div>
                  <span class="tw-mb-3 tw-block tw-text-xs tw-font-bold tw-uppercase tw-text-gray-500">Ramp
                    status</span>
                  <div v-if="!currentCampaignApplication && props.campaign?.is_hiring"
                    class="tw-flex tw-items-center tw-gap-x-1">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_341_93)">
                        <path
                          d="M10 1.66666C5.40002 1.66666 1.66669 5.4 1.66669 9.99999C1.66669 14.6 5.40002 18.3333 10 18.3333C14.6 18.3333 18.3333 14.6 18.3333 9.99999C18.3333 5.4 14.6 1.66666 10 1.66666ZM8.33335 14.1667L4.16669 9.99999L5.34169 8.82499L8.33335 11.8083L14.6583 5.48333L15.8333 6.66666L8.33335 14.1667Z"
                          fill="#2FAF82" />
                      </g>
                      <defs>
                        <clipPath id="clip0_341_93">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    <p>Now hiring</p>
                  </div>
                  <div v-else-if="!currentCampaignApplication && !props.campaign?.is_hiring">
                    <p>Not currently hiring</p>
                  </div>
                  <div v-else-if="currentCampaignApplication"
                    class="tw-inline-flex tw-items-center tw-gap-6 tw-rounded-full tw-bg-[#F5F0FF] tw-py-1 tw-pl-3 tw-pr-8 tw-text-sm tw-font-semibold tw-text-shyftoff-purple">
                    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.6922 5.43281L9.31719 1.05781C9.2591 0.999791 9.19015 0.953782 9.11428 0.922415C9.03841 0.891047 8.9571 0.874936 8.875 0.875H1.375C1.04348 0.875 0.725537 1.0067 0.491116 1.24112C0.256696 1.47554 0.125 1.79348 0.125 2.125V15.875C0.125 16.2065 0.256696 16.5245 0.491116 16.7589C0.725537 16.9933 1.04348 17.125 1.375 17.125H12.625C12.9565 17.125 13.2745 16.9933 13.5089 16.7589C13.7433 16.5245 13.875 16.2065 13.875 15.875V5.875C13.8751 5.7929 13.859 5.71159 13.8276 5.63572C13.7962 5.55985 13.7502 5.4909 13.6922 5.43281ZM9.5 3.00859L11.7414 5.25H9.5V3.00859ZM12.625 15.875H1.375V2.125H8.25V5.875C8.25 6.04076 8.31585 6.19973 8.43306 6.31694C8.55027 6.43415 8.70924 6.5 8.875 6.5H12.625V15.875ZM10.125 9.625C10.125 9.79076 10.0592 9.94973 9.94194 10.0669C9.82473 10.1842 9.66576 10.25 9.5 10.25H4.5C4.33424 10.25 4.17527 10.1842 4.05806 10.0669C3.94085 9.94973 3.875 9.79076 3.875 9.625C3.875 9.45924 3.94085 9.30027 4.05806 9.18306C4.17527 9.06585 4.33424 9 4.5 9H9.5C9.66576 9 9.82473 9.06585 9.94194 9.18306C10.0592 9.30027 10.125 9.45924 10.125 9.625ZM10.125 12.125C10.125 12.2908 10.0592 12.4497 9.94194 12.5669C9.82473 12.6842 9.66576 12.75 9.5 12.75H4.5C4.33424 12.75 4.17527 12.6842 4.05806 12.5669C3.94085 12.4497 3.875 12.2908 3.875 12.125C3.875 11.9592 3.94085 11.8003 4.05806 11.6831C4.17527 11.5658 4.33424 11.5 4.5 11.5H9.5C9.66576 11.5 9.82473 11.5658 9.94194 11.6831C10.0592 11.8003 10.125 11.9592 10.125 12.125Z"
                        fill="#5723B4" />
                    </svg>
                    <span>Applied</span>
                  </div>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
.v-card {
  &.v-card--variant-elevated {
    @apply tw-pt-0;
  }
}

.v-dialog {
  .v-card {

    .v-card-text,
    .v-card-actions,
    .v-card-title {
      @apply tw-p-0 #{!important};
    }
  }
}

::v-deep(.v-selection-control-group) {
  @apply tw-flex-row tw-gap-x-6 #{!important};

  .v-radio {
    @apply tw-border tw-border-grey-border tw-border-solid tw-rounded-lg tw-flex-row-reverse;

    &.v-selection-control--error {
      @apply tw-border-red-500 tw-text-red-500 #{!important};

      .v-label,
      i {
        @apply tw-text-red-500 #{!important};
      }
    }

    &.v-selection-control--dirty {
      @apply tw-border-shyftoff-purple;

      .v-label {
        @apply tw-text-shyftoff-purple;
      }

      .v-selection-control__input {
        i {
          @apply tw-text-shyftoff-purple;
        }
      }
    }

    .v-label {
      @apply tw-font-extrabold tw-opacity-100 tw-pl-4;
    }

    .v-selection-control__input {
      i {
        @apply tw-text-grey-border;
      }
    }
  }
}

::v-deep(.v-file-input) {
  .v-field {
    @apply tw-h-[150px];

    &.v-field--focused {
      .v-field__outline {

        .v-field__outline__start,
        .v-field__outline__end {
          @apply tw-border;
        }

        .v-field__outline__start {
          @apply tw-border-r-0;
        }

        .v-field__outline__end {
          @apply tw-border-l-0;
        }
      }
    }

    .v-field__field {
      input {
        @apply tw-cursor-pointer;
      }

      .v-field__input {
        @apply tw-hidden;
      }
    }

    &.v-field--dirty {
      @apply tw-bg-gray-100;

      .v-field__outline {

        .v-field__outline__start,
        .v-field__outline__end {
          @apply tw-border-none #{!important};
        }
      }
    }

    .v-field__outline {

      .v-field__outline__start,
      .v-field__outline__end {
        @apply tw-border-dashed tw-border-grey-border #{!important};
      }
    }

    &:hover {
      &.v-field--dirty {
        @apply tw-bg-gray-200;
      }

      .v-field__outline {

        .v-field__outline__start,
        .v-field__outline__end {
          @apply tw-border-shyftoff-purple/50 #{!important};
        }
      }

      .v-field__append-inner {
        div {
          @apply tw-opacity-100;
        }
      }
    }
  }

}

::v-deep(.v-radio-group) {
  .v-input__control {
    @apply tw-basis-full;
  }

  .v-input__details {
    @apply tw-hidden;
  }
}
</style>
