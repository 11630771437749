import { defineStore } from 'pinia'
import { ref, reactive, watch } from 'vue'
import type {
  TableOptions,
  TableType,
  TableState,
  CampaignAgentsSummary,
  Geography,
  ProductionAgent,
  CertificationInProgressAgent,
  CampaignAgentsDashboard,
  WithCountTableData,
  TableQueryParams
} from '@/types/dashboard'
import type { Campaign } from '@/types/campaign'
import { isAxiosError } from 'axios'
import axios from 'axios'
import useHelpers from '@/composables/useHelpers'

const apiBaseUrl = import.meta.env.VITE_API_URL

export const useAgentsDashboardStore = defineStore('agents_dashboard', () => {
  const loading = ref(false)
  const selectedCampaign = ref<Campaign>()
  const geographies = ref<Geography[]>([])

  const production = reactive<TableState<ProductionAgent>>({
    agents: [],
    total: 0,
    page: 1,
    itemsPerPage: 10,
    loading: false
  })

  const certification = reactive<TableState<CertificationInProgressAgent>>({
    agents: [],
    total: 0,
    page: 1,
    itemsPerPage: 10,
    loading: false
  })

  const summary = reactive<CampaignAgentsSummary>({
    total_agents: 0,
    total_production_agents: 0,
    total_certification_in_progress_agents: 0,
    campaign_agent_count_history: {
      production_agents: [],
      certification_in_progress_agents: [],
      total: []
    }
  })

  const { errorHelper } = useHelpers()

  async function getAgentsDashboardData(id: number) {
    try {
      const { data } = await axios.get<CampaignAgentsDashboard>(
        `${apiBaseUrl}/api/dashboards/by_campaign/${id}/agents?limit=10&with_count=true`
      )
      return data
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function getAgentsDashboardTableData({
    id,
    offset,
    limit,
    type = 'production',
    sort_by,
    sort_order
  }: TableQueryParams) {
    try {
      const { data } = await axios.get<
        WithCountTableData<ProductionAgent | CertificationInProgressAgent>
      >(
        `${apiBaseUrl}/api/dashboards/by_campaign/${id}/agents/${type}`,
        {
          params: {
            limit,
            offset,
            with_count: true,
            sort_by,
            sort_order
          }
        }
      )
      return data
    } catch (error) {
      if (isAxiosError(error)) {
        errorHelper(error?.response?.data)
      }
    }
  }

  async function fetchDashboardData() {
    if (!selectedCampaign.value) return

    try {
      loading.value = true
      const data = await getAgentsDashboardData(selectedCampaign.value.id)

      if (!data) return

      production.agents = data.production.results
      production.total = data.production.count

      certification.agents = data.certification_in_progress.results
      certification.total = data.certification_in_progress.count

      geographies.value = data.geographies?.results || []

      summary.total_agents = data.summary.total_agents
      summary.total_production_agents = data.summary.total_production_agents
      summary.total_certification_in_progress_agents =
        data.summary.total_certification_in_progress_agents
      summary.campaign_agent_count_history = data.summary.campaign_agent_count_history
    } catch (error) {
      console.error('Error fetching dashboard data:', error)
    } finally {
      loading.value = false
    }
  }

  async function handleTableUpdate(options: TableOptions, type: TableType) {
    if (!selectedCampaign.value) return
    console.log(options)

    const state = type === 'production' ? production : certification
    state.loading = true

    try {
      const params: any = {
        id: selectedCampaign.value.id,
        offset: (options.page - 1) * options.itemsPerPage,
        limit: options.itemsPerPage,
        type
      }

      if (options.sortBy?.[0]) {
        params.sort_by = options.sortBy[0].key
        params.sort_order = options.sortBy[0].order
      }

      const data = await getAgentsDashboardTableData(params)

      if (data) {
        state.agents = data.results
        state.total = data.count
        state.page = options.page
        state.itemsPerPage = options.itemsPerPage
      }
    } catch (error) {
      console.error(`Error updating ${type} table:`, error)
    } finally {
      state.loading = false
    }
  }

  function reset() {
    loading.value = false
    geographies.value = []

    production.agents = []
    production.total = 0
    production.page = 1
    production.itemsPerPage = 10
    production.loading = false

    certification.agents = []
    certification.total = 0
    certification.page = 1
    certification.itemsPerPage = 10
    certification.loading = false

    summary.total_agents = 0
    summary.total_production_agents = 0
    summary.total_certification_in_progress_agents = 0
    summary.campaign_agent_count_history = {
      production_agents: [],
      certification_in_progress_agents: [],
      total: []
    }
  }

  watch(selectedCampaign, () => {
    reset()
    fetchDashboardData()
  })

  return {
    loading,
    selectedCampaign,
    production,
    certification,
    geographies,
    summary,

    fetchDashboardData,
    handleTableUpdate,
    reset
  }
})
