export const mixed_bar_line_options = {
  chart: {
    type: 'line',
    animations: {
      enabled: false
    },
    zoom: {
      enabled: false
    },
    toolbar: {
      tools: {
        download: true,
        selection: false,
        zoom: false,
        pan: false,
        reset: false
      }
    }
  },
  markers: {
    size: 0,
    style: 'hollow',
  },
  stroke: {
    width: [0, 2, 2, 2, 2],
    dashArray: [0, 10, 10, 10, 10]
  },
  legend: {
    fontSize: '14px',
    fontWeight: 600,
    fontFamily: 'Nunito',
    markers: {
      size: 8,
      shape: 'circle'
    }
  },
  plotOptions: {
    bar: {
      borderRadius: 4,
      borderRadiusApplication: 'end'
    }
  }
}

export const productivity_chart_options = {
  ...mixed_bar_line_options,
  tooltip: {
      x: {
          show: true,
          formatter: function (value: string) {
              return new Date(value).toLocaleString('en-US', {
                  day: '2-digit',
                  month: 'short',
                  hour: 'numeric',
                  minute: '2-digit',
                  hour12: true
              });
          },
      }
  },
  yaxis: {
      labels: {
          show: true,
          style: {
              fontSize: '13px',
              fontFamily: 'Nunito',
          },
          formatter: (value: number) => {
              return value.toFixed(0)
          }
      },
  },
  xaxis: {
      type: 'datetime',
      labels: {
          show: true,
          style: {
              fontSize: '13px',
              fontFamily: 'Nunito',
          },
          formatter: function (value: string, timestamp: number) {
              return new Date(timestamp).toLocaleString('en-US', {
                  day: '2-digit',
                  month: 'short',
              });
          },
          offsetX: 10,
      },
      tooltip: {
          enabled: false
      },
  },
}
